<template>
  <section id="dashboard-analytics">
    <b-row class="match-height">
      <b-col lg="7">
        <timbrado-diario />
      </b-col>
      <!--<b-col lg="6">
        Chat ...
        <analytics-avg-sessions :data="data.avgSessions" />
      </b-col> -->
      <b-col lg="5">
        <balance-global />
        <!--<analytics-support-tracker :data="data.supportTracker" />-->
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <listado-cfdi />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

import StatisticCardWithAreaChart from '@core/components/statistics-cards/StatisticCardWithAreaChart.vue'
import { kFormatter } from '@core/utils/filter'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'


import ListadoCfdi from './ListadoCfdi/ListadoCfdi.vue'
import AnalyticsCongratulation from './AnalyticsCongratulation.vue'
import AnalyticsAvgSessions from './AnalyticsAvgSessions.vue'
import AnalyticsSupportTracker from './AnalyticsSupportTracker.vue'
import AnalyticsTimeline from './AnalyticsTimeline.vue'
import AnalyticsSalesRadarChart from './AnalyticsSalesRadarChart.vue'
import AnalyticsAppDesign from './AnalyticsAppDesign.vue'
import AnalyticsTimbrado from './AnalyticsTimbrado.vue'
import TimbradoDiario from './TimbradoDiario.vue'
import BalanceGlobal from './BalanceGlobal.vue'
import { API_URL } from "../../../conf/environment";
export default {
  components: {
    BRow,
    BCol,
    AnalyticsCongratulation,
    AnalyticsAvgSessions,
    StatisticCardWithAreaChart,
    AnalyticsSupportTracker,
    AnalyticsTimeline,
    AnalyticsSalesRadarChart,
    AnalyticsAppDesign,
    ListadoCfdi,
    AnalyticsTimbrado,
    TimbradoDiario,
    BalanceGlobal
  },
  setup(){
    const toast=useToast();
    return {
      toast
    }
  },
  data() {
    
    return {
      data: {}
    }
  },
  created() {
    // data 
  },
  methods: {
    kFormatter,
  },
  mounted() {
    this.$socket.client.on('timbre-recibido', data => {
     // console.log(data)
     // this.snackbar.text = `${data.from} nudged you!`
      //this.snackbar.visible = true
    /*  this.$bvToast.toast(`${data.uuid}`, {
        title: `EMISOR:${data.rfcEmisor}`,
        toaster,
        variant:`primary`,
        solid: true,79926078
      })
*/
let cfdi=data.cfdi;
     this.toast({
              component: ToastificationContent,
                  props: {
                    title: `Folio:${cfdi.serie}-${cfdi.folio}`,
                    text:`${cfdi.receptor.nombre}`,
                    icon: 'AlertTriangleIcon',
                    variant: 'success',
                  },
                },{position:'top-left'})
    })
    
    /*this.$socket.client.on('cambio-grafica',data=>{
      console.log(data);
    })*/
  }
}
</script>

import { ref, watch, computed } from '@vue/composition-api'
//import store from '@/store'
import { API_URL } from "@/env/environment";
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from '@axios'

export default function useCfdiList() {
  // Use toast
  const toast = useToast()

  const refInvoiceListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'id', label: '#', sortable: true },
    { key: 'estatus', sortable: true },
    { key: 'razonSocialReceptor', sortable: true },
    { key: 'importe', sortable: true, formatter: val => `$${val}` },
    { key: 'fechaEmision', sortable: true },
    { key: 'rfcReceptor', sortable: true },
    { key: 'rfcEmisor', sortable: true },
    { key: 'folioInterno', sortable: true },
    //{ key: 'fechaCancelacion', sortable: false,thClass: 'd-none', tdClass: 'd-none' },
    { key: 'actions' },
  ]
  const perPage = ref(10)
  const totalInvoices = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref(null)
  const searchBy=ref(null);
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const statusFilter = ref(null)

  const isFiltered=ref(false);
  const isBusy=ref(false);
  const dataMeta = computed(() => {
    const localItemsCount = refInvoiceListTable.value ? refInvoiceListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalInvoices.value,
    }
  })

  const refetchData = () => {
    refInvoiceListTable.value.refresh()
  }

  watch([currentPage, perPage, statusFilter], () => {
    refetchData()
  })

  const searchCfdi=()=>{
    isFiltered.value=true;
    refetchData()
  }
  const cleanFilter=()=>{
    isFiltered.value=false;
    searchBy.value=null;
    searchQuery.value='';
    refetchData()
  }
  const fetchInvoices =  (ctx, callback) => {
    
    isBusy.value=true;
    axios
    .get(`${API_URL}analytics/data`, { params: {
      q: searchQuery.value,
      searchBy:searchBy.value,
      searchQuery:searchQuery.value,
      perPage: perPage.value,
      page: currentPage.value-1,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      status: statusFilter.value,
    } })
    .then(response =>{
      let invoices=response.data;
      callback(invoices.items)
      totalInvoices.value = invoices.totalItems
      isBusy.value=false;
    })
    .catch(error => {
      toast({
        component: ToastificationContent,
        props: {
          title: "Error al obtener facturas' list",
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
    })

    
    /*let invoices=await $http.get(`${API_URL}analytics/data`)
      .then(response => response)
      callback(invoices.data)
      totalInvoices.value = invoices.extra.total
    console.log(data)*/
    /*store
      .dispatch('app-invoice/fetchInvoices', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        status: statusFilter.value,
      })
      .then(response => {
        const { invoices, total } = response.data

        callback(invoices)
        totalInvoices.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error al obtener facturas' list",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })*/
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveInvoiceStatusVariantAndIcon = status => {
    if (status === 'Partial Payment') return { variant: 'warning', icon: 'PieChartIcon' }
    if (status === 'Paid') return { variant: 'success', icon: 'CheckCircleIcon' }
    if (status === 'Downloaded') return { variant: 'info', icon: 'ArrowDownCircleIcon' }
    if (status === 'Draft') return { variant: 'primary', icon: 'SaveIcon' }
    if (status === 'Sent') return { variant: 'secondary', icon: 'SendIcon' }
    if (status === 'Past Due') return { variant: 'danger', icon: 'InfoIcon' }
    return { variant: 'secondary', icon: 'XIcon' }
  }

  const resolveInvoiceStatus = status => {
    //if (status === 'Partial Payment') return { variant: 'warning', icon: 'PieChartIcon' }
    if (status === 200) return { variant: 'success', icon: 'CheckCircleIcon' }
   // if (status === 'Downloaded') return { variant: 'info', icon: 'ArrowDownCircleIcon' }
  //  if (status === 'Draft') return { variant: 'primary', icon: 'SaveIcon' }
   // if (status === 'Sent') return { variant: 'secondary', icon: 'SendIcon' }
    if (status === 201) return { variant: 'danger', icon: 'InfoIcon' }
    return { variant: 'secondary', icon: 'XIcon' }
  }

  const resolveClientAvatarVariant = status => {
    if (status === 'Partial Payment') return 'primary'
    if (status === 'Paid') return 'danger'
    if (status === 'Downloaded') return 'secondary'
    if (status === 'Draft') return 'warning'
    if (status === 'Sent') return 'info'
    if (status === 'Past Due') return 'success'
    return 'primary'
  }

  return {
    fetchInvoices,
    tableColumns,
    perPage,
    currentPage,
    totalInvoices,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refInvoiceListTable,

    statusFilter,
    isBusy,
    searchBy,
    searchCfdi,
    isFiltered,
    cleanFilter,
    
    resolveInvoiceStatus,
    resolveInvoiceStatusVariantAndIcon,
    resolveClientAvatarVariant,

    refetchData,
  }
}

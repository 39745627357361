<template>
  <b-card
    v-if="grafica"
    no-body
  >
    <!-- title and dropdown -->
    <b-card-header class="pb-0">
      <b-card-title>{{ grafica.title }}</b-card-title>
      <b-dropdown
        no-caret
        right
        text="Últimos 7 Días"
        variant="transparent"
        class="chart-dropdown"
        toggle-class="p-50"
        size="sm"
      >
        <b-dropdown-item
          v-for="day in grafica.lastDays"
          :key="day"
        >
          {{ day }}
        </b-dropdown-item>
      </b-dropdown>
    </b-card-header>
    <!--/ title and dropdown -->

    <b-card-body>
      <b-row>
        <b-col
          sm="2"
          class="d-flex flex-column flex-wrap text-center"
        >
          <h1 class="font-large-2 font-weight-bolder mt-2 mb-0">
            {{ totalTimbrado }}
          </h1>
          <small>Timbres</small>
        </b-col>

        <!-- chart -->
        <b-col
          sm="10"
          class="d-flex justify-content-center"
        >

          <!-- apex chart -->
          <vue-apex-charts
            type="radialBar"
            height="250"
            ref="chartBalance"
            :options="supportTrackerRadialBar.chartOptions"
            :series="grafica.supportTrackerRadialBar.series"
          />
        </b-col>
        <!--/ chart -->
      </b-row>

      <!-- chart info -->
      <div class="d-flex justify-content-between">
        <div class="text-center">
          <b-card-text class="mb-50">
            Timbres Asignados
          </b-card-text>
          <span class="font-large-1 font-weight-bold">{{ grafica.totalTicket }}</span>
        </div>
        <div class="text-center">
          <b-card-text class="mb-50">
            Cancelados
          </b-card-text>
          <span class="font-large-1 font-weight-bold">{{ grafica.openTicket }}</span>
        </div>
        <div class="text-center">
          <b-card-text class="mb-50">
            Activos
          </b-card-text>
          <span class="font-large-1 font-weight-bold">{{ grafica.responseTime }}d</span>
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BDropdown, BDropdownItem, BCardBody, BRow, BCol, BCardText,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import axios from '@axios'
import { API_URL } from '@/env/environment'
export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BCardText,
    BCardBody,
    BRow,
    BCol,
  },
  props: {

  },
  data() {
    console.log($themeColors)
    return {
        sumaTimbrado:0,
        totalTimbre:0,
        grafica:null,
      supportTrackerRadialBar: {
        chartOptions: {
         /* chart: {
              type: 'radialBar',
              offsetY: -20,
              sparkline: {
                enabled: true
              }
        },*/
          plotOptions: {
              
            radialBar: {
                startAngle: -90,
                endAngle: 90,
                track: {
                  background: "#e7e7e7",
                  strokeWidth: '100%',
                  margin: 5, 
                  dropShadow: {
                    enabled: true,
                    top: 2,
                    left: 0,
                    color: '#999',
                    opacity: 1,
                    blur: 2
                  }
                },
                dataLabels: {
                  name: {
                    show: false
                  },
                  value: {
                    offsetY: -2,
                    fontSize: '22px'
                  }
                }
              }
          },
          colors: [$themeColors.success],
          fill: {
            type: 'solid',
            gradient: {
              shade: 'dark',
              type: 'horizontal',
              shadeIntensity: 0.5,
              gradientToColors: [$themeColors.warning],
              inverseColors: false,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100],
            },
          },
          stroke: {
           // dashArray: 20,
          },
          labels: ['Completed Tickets'],
        },
      },
    }
  },
  methods:
  {
       loadData(){
    //  console.log(this.$refs.chart.updateOptions())
    axios.get(`${API_URL}analytics/balance_global`).then(res=>res.data).then(res=>{
        console.log(res.data)
      this.grafica=res.data;
      this.sumaTimbrado=res.data.newTicket;
      /*this.charOpt.xaxis.categories=this.grafica.categories;
      this.series2=this.grafica.series;*/
        })
    },
      actualizarGrafica(){
          this.grafica.newTicket++;
          this.sumaTimbrado=parseInt(this.sumaTimbrado)+1;
          //Calcular porcentaje
            let nuevoPor=(this.grafica.newTicket/this.grafica.totalTicket)*100;
           // console.log('--',nuevoPor)
            let consumo=Number.parseFloat(nuevoPor).toFixed(1);
          // console.log(this.$refs)
          if(this.$refs.chartBalance){
this.$refs.chartBalance.updateSeries([consumo])
          }
      }
  },
  watch: {
    sumaTimbrado: function(newValue) {
     // console.log('sumaTimbrado',newValue)
      TweenLite.to(this.$data, 0.1, { totalTimbre: newValue });
    }
  },

  mounted: function (){
      this.loadData()
      this.$socket.client.on('cambio-grafica',data=>{
      this.actualizarGrafica()
    })
  },
  computed: {
    totalTimbrado: function() {
      return this.totalTimbre.toFixed(0);
    }
  }
}
</script>

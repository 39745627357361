<template>

<b-card
    
    no-body
    class="card-revenue-budget"
  >     
   <b-row class="mx-0" >
    <b-col
        md="8" v-if="loadingdata"
        class="revenue-report-wrapper"
      >
      <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong>Cargando Informacion...</strong>
        </div>
      </b-col>
      <b-col
        md="8"
        class="revenue-report-wrapper" v-if="grafica"
      >
      
        <div class="d-sm-flex justify-content-between align-items-center">
          <h4 class="card-title mb-50 mb-sm-0">
            {{ grafica.title }}
          </h4>
          <div class="d-flex align-items-center" v-if="filterChar">
            <v-select
              v-model="currenOption"
              :options="filterChar"
              :clearable="false"
              input-id="filterChari"
              @input="loadNewData"
            />
          </div>
        </div>
        <!-- chart -->
        <vue-apex-charts
          type="bar"
          height="250"
          ref="chart"
          :options="charOpt"
          :series="series2"
        />
        <!-- 
        <div class="d-flex align-items-center">
            <div class="d-flex align-items-center mr-2">
              <span class="bullet bullet-success svg-font-small-3 mr-50 cursor-pointer" />
              <span></span>
              {{formatNumber(grafica.activos)}}
            </div>
            <div class="d-flex align-items-center ml-75">
              <span class="bullet bullet-danger svg-font-small-3 mr-50 cursor-pointer" />
              <span>Cancelados</span>
            </div>
          </div>-->
      </b-col>

      <b-col
        md="4"
        class="budget-wrapper"
      >
        <div class="d-flex justify-content-center">
          <span class="font-weight-bolder mr-25">Logs-Timbrado</span>
        </div>
         <vue-perfect-scrollbar
          :settings="perfectScrollbarSettings"
        >
        <transition-group name="list" tag="div" v-bind:css="false" v-on:before-enter="beforeEnter"
    v-on:enter="enter">
          <div
          v-for="businessItem in businessItems"
          :key="businessItem.option"
        >
        <div class="d-flex align-items-center justify-content-between">
            <b-badge :variant="businessItem.badgeColor">
              {{ businessItem.folio }}
            </b-badge>
            <b-badge :variant="businessItem.badgeColor">
              {{ businessItem.fechaTimbrado }}
            </b-badge>
          </div>
        </div>
        </transition-group>
        </vue-perfect-scrollbar>        
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BDropdown, 
  BDropdownItem, BCardBody, BRow, BCol, BCardText,BButton,BLink,
  BFormCheckbox,
  BBadge,
  BListGroup,
  BListGroupItem,
  BSpinner,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import axios from '@axios'
import { API_URL } from '@/env/environment'
import Ripple from 'vue-ripple-directive'
import { formatDate, kFormatter } from '@core/utils/filter'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import vSelect from 'vue-select'

import { $themeColors } from '@themeConfig'
const $trackBgColor = '#EBEBEB'
export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BDropdown,
    BDropdownItem,
    BCardText,
    BCardBody,
    BRow,
    BCol,
    VueApexCharts,
    BButton,
    BLink,
    BFormCheckbox,
    BBadge,
    VuePerfectScrollbar,
    BListGroup,
    BListGroupItem,
    vSelect,
    BSpinner,
  },
  directives:{
    Ripple
  },
  props: {

  },
  data(props) {
   // console.log('this is props ',props.data)
    return {
      timbreActual:0,
      loadingdata:false,
      arrDataChart:[],
      filterChar:[],
      ingresoTotal:0,
      sumaIngreso:0,
      number: 0,
      currenOption:'Ultimos-7Dias',
      tweenedNumber: 0,
      businessItems: [],
      items: [1,13,14,15,16,17,18,19,21,32,33,34,35,36,37,38,39,311,312],
      nextNum: 10,
      grafica:null,
      series2:[],
      // budget chart
      charOpt:{
        grid: {
            borderColor: $trackBgColor,
            strokeDashArray: 5,
            xaxis: {
              lines: {
                show: false,
              },
            },
            yaxis: {
              lines: {
                show: true,
              },
            },
            /*padding: {
              top: -30,
              bottom: -10,
            },*/
          },
      chart: {
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
      },
      markers: {
            size: 2,
            colors: $themeColors.secondary,
            strokeColors: $themeColors.secondary,
            strokeWidth: 3,
            strokeOpacity: 1,
            strokeDashArray: 0,
            fillOpacity: 1,
            discrete: [
              {
                seriesIndex: 0,
                dataPointIndex: 5,
                fillColor: '#ffffff',
                strokeColor: $themeColors.secondary,
                size: 5,
              },
            ],
            shape: 'circle',
            radius: 1,
            hover: {
              size: 2,
            },
          },
      colors: [$themeColors.warning],
      dataLabels: {
        enabled: false,
      },
      stroke: {
        //width:6,
        curve: "straight",
       // dashArray: [3]
      },
      tooltip: {
        custom(data) {
          return `${'<div class="px-1 py-50"><span>'}${
            data.series[data.seriesIndex][data.dataPointIndex]
          }</span></div>`
        },
      },
      xaxis: {
        categories: [],
      },
      yaxis: {
        // opposite: isRtl,
      },
    }
    }
  },
  computed: {
    animatedNumber: function() {
      return this.grafica.activos.toFixed(0);
    },
    animateIngreso:function(){
      return this.formatPrice(this.sumaIngreso);
    }
  },
  watch: {
    number: function(newValue) {
      TweenLite.to(this.$data, 0.5, { tweenedNumber: newValue });
    },
    ingresoTotal:function(newValue){
      TweenLite.to(this.$data, 0.5, { sumaIngreso: newValue });
    }
  },
  methods:{
    beforeEnter: function (el) {
      el.style.opacity = 0
      el.style.height = 0
    },
    enter: function (el, done) {
      var delay = el.dataset.index * 150
      setTimeout(function () {
        Velocity(
          el,
          { opacity: 1, height: '1.6em' },
          { complete: done }
        )
      }, delay)
    },
    kFormatter,
    formatPrice(value) {
        let val = (value/1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    formatNumber(value){
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    loadData(param){
      //console.log('this is--',param,param.length)
      this.loadingdata=true;
      //this.grafica=null;
      if(param.length>4){
        axios.get(`${API_URL}analytics/grafica/${param}`).then(res=>res.data).then(res=>{
          this.grafica=res.data;
          this.loadingdata=false;
            this.arrDataChart.push({
              series:res.data.series,
              categories:res.data.categories,
              ingreso:res.data.ingreso
            })
          this.charOpt.xaxis.categories=res.data.categories;

          this.series2=[res.data.series];
                  
        })
      }else{
        //this.grafica=null;
          axios.get(`${API_URL}analytics/grafica_anual/${param}`).then(res=>res.data).then(res=>{
            this.grafica=res.data;
            this.loadingdata=false;
            this.arrDataChart.push({
                  series:res.data.series,
                  categories:res.data.categories,
                  ingreso:res.data.ingreso
                })
          
          this.charOpt.xaxis.categories=res.data.categories;
          this.$refs.chart.updateOptions({
            xaxis:{
              categories:res.data.categories
            }
          })
          this.$refs.chart.updateSeries([res.data.series])
          //actualizar chart ingreso
          //this.grafica=res.data;
       
        })
      }
    //  console.log(this.$refs.chart.updateOptions())

    },
    actualizarGrafica(item){

    if(this.currenOption==='Ultimos-7Dias'){
        let pos=this.charOpt.xaxis.categories.indexOf(item.fecha)
        if(pos>-1){
        this.series2[0].data[pos]=this.series2[0].data[pos]+1;
       // if(this.$refs.chart.updateSeries){
        this.$refs.chart.updateSeries([{
              data: this.series2[0].data
            }])
        //}
      }
      }
    
    },
    logsadd(item){
   //   console.log(cfdi)
     let cfdi=item.cfdi;
     let newitem= {
          option: `${cfdi.id}`, fechaTimbrado: `${cfdi.fecha}`,folio:`${cfdi.serie}-${cfdi.folio}`, badgeColor: 'light-success',importe:`${cfdi.total}`,
        }

      this.businessItems.splice(0, 0,newitem)
    },
    async loadNewData(option){
      //console.log(option)
      //verificar si no existe en la memoria.
      let array=await this.arrDataChart.filter(item=>item.series.name===option)
       
       if(array.length>0){
        // console.log(array[0].ingreso)
         this.$refs.chart.updateOptions({
            xaxis:{
              categories:array[0].categories
            }
          })
          this.$refs.chart.updateSeries([array[0].series])
         return;
       }
      this.loadData(option)
    }
  },
  mounted: async function (){

await this.loadData('Ultimos-7Dias');

    this.$socket.client.on('timbre-recibido',data=>{
     // console.log(data);

      this.logsadd(data);

       if(this.$refs.chart){

this.actualizarGrafica(data)

       }
      
      this.timbreActual=+1;
    })

  await this.$http.get(`${API_URL}analytics/filtro_grafica`).then(res=>res.data).then(res=>{
      this.filterChar=res.data;
    })
  },
  setup(){
    console.log('setup .....')
    const perfectScrollbarSettings = {
      maxScrollbarLength: 20,
    }
    return{
      perfectScrollbarSettings
    }
  }
}
</script>
<style>
.list-item {
  display: inline-block;
  margin-right: 10px;
}
.list-enter-active, .list-leave-active {
  transition: transform 1s;
}
.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}
.ps {
  height: 250px;
  background: black;
}
.card-revenue-budget .budget-wrapper {
    padding: 2rem 1rem;
    text-align: center;
}
</style>
